// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import MercadoLibreLogo from "../../../components/images/mercadolibre"
import GoToInstall from "../../../components/widgets/goToInstall"

type DataProps = {
    site: {
        buildTime: string
    }
}

const AmazonLanding: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="_compact">
                <h2>Facturación electrónica en Amazon</h2>
                <p className="intro">
                Permite a tus clientes obtener su factura por mensaje sin invertir tu tiempo.
                </p>
                <h3>
                    Funcionalidades
                </h3>
                <ul>
                    <li>
                        <p>
                            <b>Facturación manual sencilla:</b> Cada uno de tus pedidos podrán facturarse rápidamente y obtener el PDF y XML para tus clientes en un minuto si ya cuentas con los datos de tu cliente.<br />
                           <span className="small quote"> Por el momento no es posible mandar links a tus compradores por mensaje debido a que Amazon no lo permite</span>.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Facturación global automática:</b> Podrás contar con tu factura global a fin de mes o cada semana según prefieras, facturando los pedidos que no fueron facturados.<br />
                            <span className="small quote"> Conoce más acerca de la facturación global automática <Link to="./facturacion-global">aquí</Link></span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Fácil configuración de tus productos:</b> Configura las claves de tus productos por categoría completa o usa una sola clave predeterminada para todos tus productos. <br />
                            <span className="small">Nota: No es necesario configurar cada uno de tus productos, solamente es aecuado configurar categorías muy diferentes al resto de tu inventario.
                            </span>
                        
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Fácil configuración de impuestos:</b> De manera predeterminada, todos los productos cuentan con 16% de IVA, pero podrás definirlos como excentos o incluso agregar IEPS si lo deseas. <br />                        
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Facturación global manual:</b>  (facturas mostrador no reclamadas) Adicionalmente al proceso automático, puedes optar por realizar las facturas globales manualmente. 
                            En ella, podrás seleccionar todo el mes en curso, o inlcuso el mes anterior. <br />
                            <span className="small">Nota: El proceso de facturación global manual puede operar con decenas de miles de órdenes. </span>
                        </p>
                    </li>

                </ul>
                <h4 className="_goToInstall">
                    Conoce como realizar el registro <Link to="./register">aquí</Link> <br />
                    <span>ó revisa las secciones en la barra izquierda</span>
                </h4>
                <div className="sectionNote">
                    <p>
                        Tus cerificados están seguros con nosotros ya que <b>no guardamos</b> estos archivos, solo obtenemos la
información que requerimos, la encriptamos y posteriormente los descartamos.
                    </p>
                </div>
            </div>
        </div>
    </Layout>
)

export default AmazonLanding
